<template>
    <div>
        <div class="squares z-30 hidden lg:block" :class="position">
            <svg class="transform rotate-45"  width="425" height="425" viewBox="0 0 425 425">
                <rect id="square-top" x="20" y="0" width="275" height="275" :class="color.top" />
                <rect id="square-bottom" x="-10" y="90" width="225" height="225" :class="color.bottom" />
                <use id="use" xlink:href="#square-top" />
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            orientation: {
                type: String,
            }
        },
        computed: {
            color () {
                switch (this.orientation) {
                    case 'down':
                        return {
                            top: 'fill-black',
                            bottom: 'fill-red',
                        }
                    case 'up':
                        return {
                            top: 'fill-red',
                            bottom: 'fill-gray',
                        }
                    default:
                        throw Error('Valid orientations are up and down.')
                }
            },
            position() {
                switch (this.orientation) {
                    case 'down':
                        return 'squares-down'
                    case 'up':
                        return 'squares-up'
                    default:
                        throw Error('Valid orientations are up and down.')
                }
            }
        }     
    }
</script>

<style scoped>
    .squares-down {
        @apply absolute -right-40 -top-50 z-20
    }
    .squares-up {
        @apply absolute left-0 -top-40
    }
</style>