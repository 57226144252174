<template>
    <div class="w-full lg:w-auto flex">
        <nav :class="style" class="w-full lg:w-auto flex items-center justify-between flex-wrap lg:p-3">
            <div
                v-if="region == 'header'"
                @click="mobileNav"
                class="block lg:hidden absolute z-1 flex items-center justify-center w-10 h-10 bg-red-600 right-6 top-6 transform rotate-45 shadow-lg"
            >
                <button class="flex items-center text-red-100 hover:text-white transform -rotate-45">
                    <i v-if="!toggleMobileNav" class="gg-menu"></i>
                </button>
            </div>
            <div v-if="!toggleMobileNav" class="hidden lg:block">
                <NavigationDesktop :region="region" @openContactForm="openContactForm" />
            </div>
            <div v-if="toggleMobileNav">
                <NavigationMobile @closeMobileMenu="closeMobileMenu" @openContactForm="openContactForm" />
            </div>
        </nav>
    </div>
</template>

<script>
    import NavigationDesktop from '@/components/navigation/NavigationDesktop'
    import NavigationMobile from '@/components/navigation/NavigationMobile'

    export default {
        name: 'navigation',
        props: {
            region: {
                type: String,
            }
        },
        components: {
            NavigationDesktop,
            NavigationMobile,
        },
        computed: {
            style() {
                switch (this.region) {
                    case 'header':
                        return 'main-nav-header'
                    case 'footer':
                        return 'main-nav-footer'
                    default:
                        throw Error('Invalid region ' + this.region + '. Valid regions are header and footer.')
                }
            },
            path () {
                return this.$route.path
            }
        },
        data () {
			return {
                toggleMobileNav: false,
                toggleModal: false,
			}
		},
		methods: {
            closeMobileMenu (value) {
                this.toggleMobileNav = value
            },
            openContactForm (value) {
                this.toggleMobileNav = false
                this.toggleModal = value
            },
            mobileNav () {
                return this.toggleMobileNav = !this.toggleMobileNav
            },
            modal () {
                return this.toggleModal = !this.toggleModal
            }
		},
    }
</script>

<style scoped>
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/album.css');
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/bulb.css');
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/close.css');
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/eye.css');
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/image.css');
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/mail-open.css');
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/menu.css');
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/presentation.css');
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/printer.css');

    .main-nav-footer {
        @apply flex justify-center text-gray-500 mb-6;
    }
    .main-nav-footer a:hover {
        @apply bg-red-700 text-red-100;
    }
</style>