<template>
    <ul 
        :class="region == 'footer' ? 'text-gray-500' : 'text-gray-700'"
        class="p-6 lg:p-0 uppercase flex-grow flex-col md:flex-row h-screen md:h-auto w-screen lg:w-auto text-sm font-body flex md:items-center md:justify-center"
    >
        <li class="">
            <router-link 
                to="/about" 
                :class="path == '/about' ? 'active' : ''" 
                class="link px-2 py-4 xl:px-4 hover:bg-gray-800 hover:text-gray-200 hover:font-bold"
            >
                About
            </router-link>
        </li>
        <li class="">
            <router-link 
                to="/design" 
                :class="path == '/design' ? 'active' : ''" 
                class="link px-2 py-4 xl:px-4 hover:bg-gray-800 hover:text-gray-200 hover:font-bold"
            >
                Design
            </router-link>
        </li>
        <li class="">
            <router-link 
                to="/branding" 
                :class="path == '/branding' ? ' active' : ''" 
                class="link px-2 py-4 xl:px-4 hover:bg-gray-800 hover:text-gray-200 hover:font-bold"
            >
                Branding
            </router-link>
        </li>
        <li class="">
            <router-link 
                to="/printing" 
                :class="path == '/printing' ? 'active' : ''" 
                class="link px-2 py-4 xl:px-4 hover:bg-gray-800 hover:text-gray-200 hover:font-bold"
            >
                Printing
            </router-link>
        </li>
        <li class="">
            <router-link 
                to="/portfolio" 
                :class="path == '/portfolio' ? ' active' : ''" 
                class="link px-2 py-4 xl:px-4 hover:bg-gray-800 hover:text-gray-200 hover:font-bold"
            >
                Portfolio
            </router-link>
        </li>
        <li class="">
            <a to="/" @click="openContactForm" class="link px-2 py-4 xl:px-4 hover:bg-red-600 hover:text-red-100 hover:font-bold cursor-pointer flex">
                Get your answer                        
            </a>
        </li>
    </ul>
</template>

<script>
    import ContactForm from '@/components/forms/ContactForm'
    import { ModalBus } from '@/eventBus'
    
    export default {
        name: 'NavigationDesktop',
        props: {
            region: {
                type: String,
            }
        },
        components: {},
        computed: {
            path () {
                return this.$route.path
            },
        },
        methods: {
            openContactForm() {
				ModalBus.$emit('open', { component: ContactForm })
			}
        }
    }
</script>

<style scoped>
    .active {
        @apply text-base;
        position: relative;
        font-weight: 600;
        margin-top: -3px;
    }
    .active:hover {
        @apply bg-gray-100 text-gray-900;
    }
    .active:before {
        @apply bg-red-600 mr-1;
        content: '';
        display: block;
        width: 13px;
        height: 14px;
        margin-top: 2px;
    }
    .link {
        @apply flex transition-colors duration-500 ease-in-out;
        font-weight: 500;
    }
    .link:hover {
        font-weight: 600;
    }
</style>