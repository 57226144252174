<template>
    <div class="absolute mobile-menu overflow-y-visible top-0 w-full bg-gray-900 text-gray-300 lg:hidden">
        <div 
            @click="closeMobileMenu" 
            class="absolute top-6 right-6 z-50 cursor-pointer flex items-center justify-center w-10 h-10 bg-red-600 transform rotate-45"
        >
            <div class="transform -rotate-45">
                <i class="gg-close text-red-100"></i>
            </div>
        </div>

        <ul 
            class="lg:p-0 text-sm flex-grow flex-col lg:flex-row lg:h-auto lg:w-auto font-condensed flex lg:items-center lg:justify-center h-screen overflow-y-auto"
        >
            <li class="px-6 pt-6 pb-6">
                <router-link 
                    to="/" 
                    class=""
                >
                    <img 
                        src="@/assets/img/logo-inverted.svg" 
                        class="w-40"
                    >
                </router-link>
            <li>
            <li class="mx-3">
                <router-link 
                    to="/about" 
                    :class="path == '/about' ? 'bg-gray-800 text-gray-100 font-bold' : ''" 
                    class="px-1 py-4 hover:bg-gray-800 hover:text-gray-200 hover:font-bold flex"
                >
                    <div 
                        :class="path == '/about' ? 'text-red-700' : 'text-red-900'"
                        class="w-10 flex justify-center"
                    >
                        <i class="gg-eye"></i>
                    </div>
                    <span>About</span>
                </router-link>
            </li>
            <li class="mx-3">
                <router-link 
                    to="/design" 
                    :class="path == '/design' ? 'bg-gray-800 font-semibold text-gray-200' : ''" 
                    class="px-1 py-4 hover:bg-gray-800 hover:text-gray-200 hover:font-bold flex"
                >
                    <div 
                        :class="path == '/design' ? 'text-red-700' : 'text-red-900'"
                        class="w-10 flex justify-center"
                    >
                        <i class="gg-image"></i>
                    </div>
                    <span>Design</span>
                </router-link>
            </li>
            <li class="mx-3">
                <router-link 
                    to="/branding" 
                    :class="path == '/branding' ? 'bg-gray-800 font-semibold text-gray-200' : ''" 
                    class="px-1 py-4 hover:bg-gray-800 hover:text-gray-200 hover:font-bold flex"
                >
                    <div 
                        :class="path == '/branding' ? 'text-red-700' : 'text-red-900'"
                        class="w-10 flex justify-center"
                    >
                        <i class="gg-presentation"></i>
                    </div>
                    <span>Branding</span>
                </router-link>
            </li>
            <li class="mx-3">
                <router-link 
                    to="/printing" 
                    :class="path == '/brochure' ? 'bg-gray-800 font-semibold text-gray-200' : ''" 
                    class="px-1 py-4 hover:bg-gray-800 hover:text-gray-200 hover:font-bold flex"
                >
                    <div
                        :class="path == '/printing' ? 'text-red-700' : 'text-red-900'" 
                        class="w-10 flex justify-center"
                    >
                        <i class="gg-printer"></i>
                    </div>
                    <span>Printing</span>
                </router-link>
            </li>
            <li class="mx-3">
                <router-link 
                    to="/portfolio" 
                    :class="path == '/portfolio' ? 'bg-gray-800 font-semibold text-gray-200' : ''" 
                    class="px-1 py-4 hover:bg-gray-800 hover:text-gray-200 hover:font-bold flex"
                >
                    <div
                        :class="path == '/portfolio' ? 'text-red-700' : 'text-red-900'" 
                        class="w-10 flex justify-center"
                    >
                        <i class="gg-album"></i>
                    </div>
                    <span>Portfolio</span>
                </router-link>
            </li>
            <li class="bg-black m-6 shadow-lg">
                <a @click="openContactForm" to="/" class="p-6 hover:bg-gray-800 hover:text-red-100 hover:font-bold cursor-pointer flex justify-center items-center">
                    <div class="flex items-center h-full pr-3">
                        <div class="flex justify-center -mt-3 text-red-600">
                            <i  class="gg-bulb"></i>
                        </div>
                    </div>
                    <div class="flex flex-col pl-3">
                        <span class="font-bold font-heading text-2xl">Feeling inspired?</span> 
                        <span class="font-body font-light text-lg text-gray-400">Let's create your <span class="text-red-600 font-semibold">answer</span></span>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    import ContactForm from '@/components/forms/ContactForm'
    import { ModalBus } from '@/eventBus'

    export default {
        name: 'NavigationMobile',
        props: {},
        components: {},
        computed: {
            path () {
                return this.$route.path
            }
        },
        methods: {
            closeMobileMenu () {
                this.$emit('closeMobileMenu', false)
            },
            openContactForm () {
                this.$emit('closeMobileMenu', false)
                ModalBus.$emit('open', { component: ContactForm })
            }
        }
    }
</script>

<style scoped>
    .gg-album,
    .gg-eye,
    .gg-image,
    .gg-presentation,
    .gg-printer {
        --ggs: .75;
    }
    .gg-bulb {
        --ggs: 1.75;
    }
</style>