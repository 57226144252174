<template>
    <footer :class="height != 'short' ? 'lg:pt-16' : 'lg:pt-8'" class="bg-gray-900 px-8 pb-8 pt-48">
        <div class="container mx-auto flex flex-col items-center">
            <Navigation region="footer"/>
            <span class="font-body font-light text-gray-600 text-sm">Copyright <span class="text-red-800">&copy;</span> 2020 <span class="text-gray-500 font-normal">iNQUiSiTiON</span></span>
        </div>
    </footer>
</template>

<script>
    import Navigation from './Navigation'

    export default {
        name: 'Footer',
        props: {
            height: {
                type: String,
            }
        },
        components: {
            Navigation
        }
    }
</script>