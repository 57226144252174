<template>
    <div class="contact flex flex-col lg:flex-row lg:justify-between">
        <div class="w-full lg:w-1/2 p-8 lg:p-12 bg-gray-100 text-gray-700 font-body">
            <h3 class="text-2xl lg:text-3xl mb-3 lg:mb-4 font-light text-gray-800">Let's create your <span class="font-semibold text-red-600">answer</span></h3>

            <div class="mb-4 lg:mb-6 text-sm lg:text-lg">
                <p class="flex items-center mb-1"><i class="gg-phone text-red-600 mr-2"></i><a href="tel:+44 (0)7736 933 897">+44 (0)7736 933 897</a></p>
                <p class="flex items-center"><i class="gg-mail text-red-600 mr-2"></i><a href="mailto:jon@inqusitiondesign.com">jon@inquisitiondesign.com</a></p>
            </div>

            <p class="text-xs lg:text-base font-bold lg:mb-4 leading-loose">In order to ensure to a quick and accurate response to your query, please be as descriptive as possible.</p>

            <div class="hidden lg:block">
                <p class="mb-4 text-sm leading-relaxed"><span class="font-semibold">For printing</span>, please tell us the type of item you need designing or printing e.g. business cards, roller banners, branding etc. Please also provide details about the quantitiy and the material to be printed on and include any special requests relating to the finishing, folding or lamination. </p>

                <p class="mb-4 text-sm leading-relaxed"><span class="font-semibold">For design</span> enquiries, please provide a rough overview of the project and will be in touch to help you create your answer.</p>

                <p class="mb-4 text-sm leading-relaxed"><span class="font-semibold">For branding</span>, please give us as much detail as you can about your brand, company, organistation or product as possible; including any specific requirements relating to colour usage or existing branding.</p>

                <p class="text-sm leading-relaxed"><span class="font-semibold">For anything else</span>, just drop us a message and let's have a chat.</p>
            </div>
        </div>

        <div class="contact-form w-full lg:w-1/2 p-6 lg:p-12 lg:pl-6 bg-white text-left">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(onSubmit)" class="w-full lg:max-w-lg">
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-heading font-bold mb-2" for="grid-first-name">
                                First Name
                            </label>

                            <validation-provider rules="required" v-slot="{ errors }">
                                <input
                                    v-model="form.fields.firstName"
                                    name="firstname"
                                    type="text"
                                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                />
                                <span class="form-error">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-heading font-bold mb-2" for="grid-last-name">
                                Last Name
                            </label>
                            <input
                                v-model="form.fields.lastName"
                                type="text"
                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            >
                        </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-heading font-bold mb-2" for="grid-password">
                                Email
                            </label>
                            <validation-provider rules="required|email" v-slot="{ errors }">
                                <input
                                    v-model="form.fields.email"
                                    name="email"
                                    type="text"
                                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                />
                                <span class="form-error">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-heading font-bold mb-2" for="grid-password">
                                Phone
                            </label>
                            <input
                                v-model="form.fields.phone"
                                name="phone"
                                type="phone"
                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            >
                        </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-full px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-heading font-bold mb-2" for="grid-state">
                                How can we help?
                            </label>
                            <div class="relative">
                                <select
                                    v-model="form.fields.service"
                                    class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                >
                                    <option>Branding</option>
                                    <option>Design</option>
                                    <option>Printing</option>
                                    <option>Something else</option>
                                </select>
                                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-2">
                        <div class="w-full px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-heading font-bold mb-2" for="grid-password">
                                Message
                            </label>
                            <validation-provider rules="required" v-slot="{ errors }">
                                <textarea
                                    v-model="form.fields.message"
                                    name="mesage"
                                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password"
                                ></textarea>
                                <span class="form-error">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>
                    </div>
                    <div class="flex justify-center lg:justify-between flex-wrap lg:flex-no-wrap -mx-3 p-3">
                        <vue-recaptcha
                            :loadRecaptchaScript="true"
                            :sitekey="form.recaptcha.sitekey"
                            @verify="onVerify"
                        ></vue-recaptcha>
                        <button class="lg:rounded w-full send bg-red-600 hover:bg-red-700 text-white py-3 px-4 focus:outline-none focus:shadow-outline font-semibold font-condensed" type="submit">Send</button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
    import { ModalBus } from '@/eventBus'
    import Alert from '@/components/common/Alert'
    import {ValidationObserver, ValidationProvider, extend } from 'vee-validate';
    import { required, email } from 'vee-validate/dist/rules';
    import VueRecaptcha from 'vue-recaptcha';

    extend('email', {
        ...email,
        message: 'Please provide a valid email address'
    });
    extend('required', {
        ...required,
        message: 'This field is required'
    });

    export default {
        name: 'contact-form',
        components: {
            ValidationObserver,
            ValidationProvider,
            VueRecaptcha,
        },
        data() {
            return {
                form: {
                    fields: {
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        service: '',
                        message: '',
                    },
                    recaptcha: {
                        robot: true,
                        sitekey: '6LeZGswZAAAAAESyODr0EomROcJTZoDsPyi-qRZT',
                    }
                }
            }
        },
        methods: {
            onSubmit () {
                if (!this.form.recaptcha.robot) {
                    const requestOptions = {
                        method: 'POST',
                        body: JSON.stringify(this.form.fields)
                    };

                    fetch('http://inquisitiondesign.com/contact.php', requestOptions).then(async response => {
                        if (!response.ok) {
                            const error = response.status;
                            return Promise.reject(error);
                        }

                        this.$emit('on-close')

                        ModalBus.$emit('open', {
                            component: Alert,
                            alert: true,
                            props: {
                                message: 'Thank you, your message was successfully sent. One of our team will be in touch shortly.',
                                status: 'success'
                            }
                        })
                    })
                }
            },
            onVerify: function (response) {
                if (response) this.form.recaptcha.robot = false;
            },
        }
    }
</script>

<style scoped>
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/phone.css');
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/mail.css');

    .form-error {
        @apply text-xs text-red-600;
    }
    .send {
        @apply mt-5
    }

    @media (min-width: 1024px) {
        .send {
            margin-top: 1px;
            margin-bottom: 2px;
        }
    }
</style>