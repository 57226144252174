<template>
    <div
        :class="state.error ? 'bg-red-700' : 'bg-green-600'"
        class="alert px-6 py-8 max-w-sm flex flex-wrap"
    >
        <div class="w-full text-green-100 font-body flex items-center">
            <div class="p-3 flex justify-center items-center">
                <div v-if="state.success">
                    <i class="gg-check"></i>
                </div>
                <div v-if="state.error">
                    <i class="gg-close-o"></i>
                </div>
            </div>

            <p class="text-sm font-condensed">{{ message }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Alert',
        props: {
            message: {
                type: String,
            },
            status: {
                type: String
            }
        },
        computed: {

        },
        data () {
            return {
                state: {
                    error: this.status == 'error',
                    info: this.status == 'info',
                    success: this.status == 'success',
                }
            }
        }
    }
</script>

<style scoped>
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/check.css');
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/close-o.css');

    .gg-check {
        --ggs: 2;
    }
</style>