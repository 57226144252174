<template>
	<header class="bg-white shadow-sm fixed z-40 w-full">
		<div class="container xl:mx-auto">
			<div class="flex flex-wrap items-center">
				<router-link 
					to="/" 
					class="p-6 lg:p-3 lg:mr-5 xl:mr-6"
				>
					<img 
						src="@/assets/img/logo.svg" 
						class="w-40 xl:w-64 "
					>
				</router-link>

				<Navigation region="header"/>
			</div>
		</div>
		<div class="hidden lg:block"> 
			<Squares orientation="down"/>
		</div>
	</header>
</template>

<script>
	import Navigation from './Navigation.vue'
	import Squares from './Squares.vue'
	
	export default {
		name: 'header',
		components: {
			Navigation,
			Squares
		}
	}
</script>